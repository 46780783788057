import { Component, OnInit } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-uputstvo',
  templateUrl: './uputstvo.component.html',
  styleUrls: ['./uputstvo.component.css']
})
export class UputstvoComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Uputstvo za instalaciju - Adacco programi');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Instalacija, sql server, software, Adacco, cloud'},
      {name: 'description', content: 'Poslovni i knjigovodstveni programi - Uputstvo za instalaciju '},
      {name: 'author', content: 'Adacco'}
    ] );

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 350, behavior: 'smooth'});
    }
  }

}
