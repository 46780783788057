import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-instalacija',
  templateUrl: './instalacija.component.html',
  styleUrls: ['./instalacija.component.css']
})
export class InstalacijaComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  navigajUpgrade() {
    // alert('aaa');
    let n = Date.now();

    const url = '/upgrade?id=' + n.toString();
    this.router.navigateByUrl(url);
  }

  navigajGinstalacija() {
    // alert('baa');
    let n = Date.now();

    const url = '/ginstalacija?id=' + n.toString();
    this.router.navigateByUrl(url);
  }
}
