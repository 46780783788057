import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { environment } from '../../environments/environment';
import {MessageComponentService} from '../message.component.service';
import {Dekl} from '../dekl.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID} from '@angular/core';

import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-cenovnik',
  templateUrl: './cenovnik.component.html',
  styleUrls: ['./cenovnik.component.css']
})
export class CenovnikComponent implements OnInit {
  globv = environment;

  constructor(
    private messageService: MessageComponentService,
    @Inject(PLATFORM_ID) private platformId: object,
    public dekl: Dekl,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Adacco programi Cenovnik');
    this.metaTagService.addTags( [ ///updateTag
      {name: 'keywords', content: 'Cenovnik, Cene knjigovodstveni, software, Adacco'},
      {name: 'description', content: 'Cenovnik Adacco programa. Simbolične cene, vrhunski softver'},
      {name: 'author', content: 'Adacco'}
      // ,
      // {name: 'robots', content: 'index, follow'}
     ] );

    if (this.globv.cjenik === 3) {
      this.saljieconomic();
      this.globv.cjenik = 0;
    } else {
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    }
  }

  saljieconomic() {
    // alert("econ"):
    this.dekl.tekst3 = 'economic';
    this.globv.emit_to_page = 'gcjenik';
    this.messageService.sendMessage2(this.dekl);
  }

  saljiprofesional() {
    // alert("econ"):
    this.dekl.tekst3 = 'profesional';
    this.globv.emit_to_page = 'gcjenik';
    this.messageService.sendMessage2(this.dekl);
  }

  saljirent() {
    // alert("econ"):
    this.dekl.tekst3 = 'rent';
    this.globv.emit_to_page = 'gcjenik';
    this.messageService.sendMessage2(this.dekl);
  }

  saljicomplete() {
    // alert("econ"):
    this.dekl.tekst3 = 'complete';
    this.globv.emit_to_page = 'gcjenik';
    this.messageService.sendMessage2(this.dekl);
  }

}
