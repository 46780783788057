import { Component, OnInit } from '@angular/core';
import {Dekl} from '../dekl.service';
import { environment } from '../../environments/environment';

import { Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  globv = environment;

  category: Array<any> = [{
    catnumber: 1,
    nest: [
      {
        link: 1
      },
      {
        link: 2
      }
    ]
  },
    {
      catnumber: 2,
      nest: [
        {
          link: 1
        },
        {
          link: 2
        },
      ]
    },
    {
      catnumber: 3,
      nest: [
        {
          link: 1
        },
        {
          link: 2
        }
      ]
    },
  ];

  flag: boolean = false;

  catchEvent(event) {
    this.flag = true
    console.log(event)
  }

  hide(event) {
    this.flag = false;
  }

  constructor(
    public dekl: Dekl,
     @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Adacco knjigovodstveni programi');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Knjigovodatvo, Knjigovodstveni programi, software, Adacco, programi za knjigovodstvo'},
      {name: 'description', content: 'Set poslovnih i knjigovodstvenih programa - cloud platforma'},
      {name: 'author', content: 'Adacco'}
      // ,
      // {name: 'robots', content: 'index, follow'}
    ] );


    // window.scroll(0, 0);
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
    this.dekl.tpage = 'home';
  }

  cjenikeconomic() {
    this.globv.cjenik = 3;
  }

}
