import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-instalacijas',
  templateUrl: './instalacijas.component.html',
  styleUrls: ['./instalacijas.component.css']
})
export class InstalacijasComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  navigajUpgrade() {
    // alert('aaa');
    let n = Date.now();

    const url = '/upgrades?id=' + n.toString();
    this.router.navigateByUrl(url);
  }

  navigajGinstalacija() {
    // alert('baa');
    let n = Date.now();

    const url = '/ginstalacijas?id=' + n.toString();
    this.router.navigateByUrl(url);
  }
}
